import * as React from 'react'
import {graphql, Link, useStaticQuery} from "gatsby";
import {useEffect, useState} from "react";
import {getShopURL} from "../../logic/seo";

export default function DateTimeColumn({item}) {
    const store = item.row.original
    const date = new Date(item.value)

    const meta = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          formats {
            timeFormat {
                hour
                minute
            }
            dateTimeFormat {
                hour
                minute
                day
                month
                year
            }
        }
        }
        
      }
    }
  `)

    return (
        <span>
            {date.toLocaleDateString()+" " + date.toLocaleString([], meta.site.siteMetadata.formats.timeFormat)}
        </span>
    )
}