import * as React from 'react'
import {graphql, Link, useStaticQuery} from "gatsby";
import {useEffect, useState} from "react";
import {getShopURL} from "../../logic/seo";

export default function ShopNameColumn({item}) {
    const store = item.row.original

    return (
        <span>
            <a href={store.urlAffiliate || store.url}>{store.name}</a> (<a href={getShopURL(store.slug)}>Details</a>)
        </span>
    )
}