import * as React from 'react'

import {useTable, useFilters, useSortBy} from 'react-table'
import ShopNameColumn from "./overview-table/shop-name-column";
import DateTimeColumn from "./overview-table/shop-last-order-date-time-field";
import {useState} from "react";
import './overview-table/common.css'
import {inTime, storeDeliveryStatus} from "../logic/dateComparisons";

export default function OverViewTable({stores}) {


    const data = stores


    const [filterInput, setFilterInput] = useState('')

    const handleFilterChange = e => {
        let value = e.target.value || undefined;
        console.log(e)
        if (e.target.type === 'checkbox') {
            value = e.target.checked ? true : null
        }
        console.log(value)
        setFilter(e.target.name, value)
    }

    const columns = React.useMemo(
        () => [

            {
                Header: 'Shop',
                accessor: 'name', // accessor is the "key" in the date
                Cell: props => <ShopNameColumn item={props}></ShopNameColumn>,
                defaultCanFilter: true,
            },
            {
                Header: 'Sortiment',
                accessor: 'products',
            },
            {
                Header: 'Letztes Bestelldatum',
                accessor: 'lastOrderTime',
                Cell: props => <DateTimeColumn item={props}></DateTimeColumn>,
            },
            {
                Header: 'Letztes Bestelldatum (Premium)',
                accessor: 'lastOrderTimePremium',
                Cell: props => <DateTimeColumn item={props}></DateTimeColumn>,
            },
            {
                Header: 'Letztes Bestelldatum ok',
                accessor: 'lastOrderTimeOk',
                filter: 'equal'
            },
            {
                Header: 'Letztes Bestelldatum Premium ok',
                accessor: 'lastOrderTimePremiumOk',
                filter: 'equal'
            },
        ],

        []
    )


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setFilter
    } = useTable({columns, data, initialState: {
        hiddenColumns: ['lastOrderTimeOk', 'lastOrderTimePremiumOk']
        } }, useFilters, useSortBy)


    return (

        <div>
            <div className=''>
                <input onChange={handleFilterChange} placeholder="Onlineshop suchen" name='name' className="form-control"/>
            </div>
            <div>
                <input type='checkbox' name='lastOrderTimeOk' onChange={handleFilterChange}/>
                <label>nur rechtzeitige Lieferung im Standardversand anzeigen </label>
            </div>

            <div>
                <input type='checkbox' name='lastOrderTimePremiumOk' onChange={handleFilterChange}/>
                <label>nur rechtzeitige Lieferung im Premiumversand anzeigen </label>
            </div>



            <table {...getTableProps()} className="table">
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th scope="col" {...column.getHeaderProps(column.getSortByToggleProps())}
                                className={column.isSorted ? column.isSortedDesc ? 'desc' : 'asc' : 'both'}>
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    console.log(row)
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()} className={storeDeliveryStatus(row.original, Date.now())
                        }>
                            {row.cells.map(cell => {
                                return (
                                    <td {...cell.getCellProps()} >
                                        {cell.render('Cell')}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
                </tbody>

            </table>
        </div>


    )

}