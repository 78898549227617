import * as React from 'react'
import DefaultLayout from "../layouts/default";
import {graphql, Page} from "gatsby";
import AffiliateNotice from "../components/affiliate-notice";
import OverViewTable from "../components/overview-table";
import {inTime} from "../logic/dateComparisons";
import {getDefaultTitle} from "../logic/seo";
import {Helmet} from "react-helmet";

export default class ShopOverview extends React.Component<Page, { props }> {
    public render() {

        return (
            <DefaultLayout>
                <Helmet>
                    <title>{getDefaultTitle("Übersicht aller Onlineshops, die noch vor Weihnachten liefern")}</title>
                    <meta name="description" content="Übersicht der letztmöglichen Bestelltermine aller deutschen Onlineshops mit Premium- oder Standardversand."/>

                </Helmet>
                <h1>Lieferfristen aller deutschen Onlineshops in der Übersicht</h1>
                <OverViewTable stores={
                    this.props.data.allStrapiStores.nodes.map(store => {
                        return {
                            ...store,
                            lastOrderTimeOk: inTime(store.lastOrderTime, Date.now()),
                            lastOrderTimePremiumOk: inTime(store.lastOrderTimePremium, Date.now()),
                            lastOrderTimePremium: store.lastOrderTimePremium || store.lastOrderTime,
                        }
                    })
                }></OverViewTable>

                <AffiliateNotice></AffiliateNotice>


            </DefaultLayout>
        )
    }
}

export const query = graphql`
query MyQuery {
  allStrapiStores {
    nodes {
      headlineH1
      highlighted
      lastOrderTime
      lastOrderTimePremium
      name
      slug
      text
      title
      products
      url
      urlAffiliate
      icon {
        url
        formats {
          large {
            url
          }
          medium {
            url
          }
          small {
            url
          }
        }
        name
        alternativeText
        caption
      }
      updated_at

    }
  }
}
`